import * as React from "react"
import { db } from '../../../firebase-info';
import { collection, doc, onSnapshot, where, query, updateDoc} from "firebase/firestore";
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Backdrop, CircularProgress, Grid } from "@mui/material"
import BeGeneralPage from "../../components/be-general-page";
import EnhancedTable from "../../components/EnhancedTable";
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';

// markup
const ValidatePage = () => {
	const { t } = useTranslation();
  const [recentResearchers, setRecentResearchers] = React.useState([])
  const [loading, setLoading] = React.useState(true)

    const headers = [
        {
          id: 'fullname',
          numeric: false,
          disablePadding: true,
          label: t('Full Name'),
        },
        {
          id: 'email',
          numeric: false,
          disablePadding: false,
          label: t('Email'),
        },
        {
          id: 'creationDate',
          numeric: false,
          disablePadding: false,
          label: t('Register date'),
        },
        {
          id: 'organization',
          numeric: false,
          disablePadding: false,
          label: t('Organization'),
        },
      ];

	async function getDocumentData() {
    const q = query(collection(db, "users"), where("isAdminValidated", "==", false));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let researchers = [];
        querySnapshot.forEach((doc) => {
            researchers.push({
                id: doc.id,
                ref: doc.ref,
                ...doc.data(),
            });
        });

        researchers = researchers.map(elem => {
            const date = elem.creationDate;

            delete elem.creationDate;

            elem.creationDate = date.toDate().toLocaleString();

            return elem;
        })
        setRecentResearchers(researchers);
        setLoading(false);
    });
	};

    async function validateResearchers(selected) {
        selected.forEach(async (userId) => {
            const researcherRef = doc(db, "users", userId)
            await updateDoc(researcherRef, {
                isAdminValidated: true
            })
        })
    }

  React.useEffect(() => {
	  getDocumentData();
	}, []);


  return (
    <BeGeneralPage title={t("Validate New Researchers")}>
        {loading ?
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        :
            <EnhancedTable 
                title={t("These researchers are pending of validation")}
                headers={headers}
                rows={recentResearchers} 
                onAction={validateResearchers}
                actionIcon={<VerifiedUserRoundedIcon fontSize="large"/>}
            />
        }
    </BeGeneralPage>
  )
}

export default ValidatePage

export const graphQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "administration_validate", "enhanced_table"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;